import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`El carpòfor té forma esfèrica de 0,5 a 1,5 cm, blanquinosa o marronosa amb un peristoma apical de 0,5 mm d’altura. Té un peu que aguanta el carpòfor de 2-4 cm i 0,2 cm de diàmetre, dur, fibrós, color marró clar. Gleva color rovell. Les espores són globoses, amb algunes berrugositats, de 4-6 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      